import React from "react";
import { Link } from "gatsby";
import SEO from "../components/seo";
import { encodeQueryParameter } from "utils/queryParameters";

const IndexPage = () => {
  //hard-coding query parameters for testing
  const SAMPLE_EMAIL = "yyon@thomasnet.com";
  const email = encodeQueryParameter(SAMPLE_EMAIL);
  const messageId = 21384;
  return (
    <>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <header className="page-header">
        <div className="container">
          <h1 className="page-header__title">Home</h1>
        </div>
      </header>

      <section className="page-body">
        <div className="container pt-0">
          <div style={{ maxWidth: 300 }}></div>
          <p>
            <Link to={`/email-preferences/?email=${email}`}>
              Go to Email Preferences
            </Link>
          </p>
          <p>
            <Link to={`/unsubscribed/?email=${email}&message=${messageId}`}>
              Go to unsubscribe
            </Link>
          </p>
        </div>
      </section>
    </>
  );
};

export default IndexPage;
