export const encodeQueryParameter = (queryString: string) => {
  return encodeURIComponent(queryString);
};

// export const encryptQueryParameter = (queryString: string) => {
//   return btoa(`email=${queryString}`);
// };

export const decodeQueryParameter = (queryString: string) => {
  if (!queryString) {
    return "";
  }

  return decodeURIComponent(queryString).replace(/^.*?\=/, "");
};

// export const decryptQueryParameter = (queryString: string) => {
//   return atob(queryString.replace("?", ""));
// };

export const parseUrlSearch = (search: string): { [key: string]: string } => {
  const parts = search.substr(1).split("&");
  return parts.reduce((prev, val) => {
    const [key, value] = val.split("=");
    return { ...prev, [key]: value };
  }, {});
};
